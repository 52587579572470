import { useRouter } from "next/router";
import OTPInput from "@components/otp-input/OTPInput";
import { signIn } from "next-auth/react";

import { Button, showToast } from "@gility/ui";

import type { AuthMethodLogic, AuthStepActionsProps, AuthStepProps } from "./types";
import { StepActionsContainer, StepContainer } from "./StepContainer";
import { useAuthStore } from "./store";
import { AuthStep } from "./types";

export const SmsStep = () => {
  const setOtpCode = useAuthStore((store) => store.setOtpCode);

  return (
    <StepContainer step={AuthStep.smsOtp}>
      Ti abbiamo inviato un codice di verifica. Inseriscilo qui sotto.
      <OTPInput
        title="Codice di verifica"
        length={6}
        onChange={(value) => setOtpCode(value)}
        className="mt-8"
        upcaseOnly
      />
    </StepContainer>
  );
};

export const SmsStepActions = (_props: AuthStepActionsProps) => {
  const store = useAuthStore();
  const router = useRouter();

  // const { mutate: sendSmsOtp, isLoading: isSendingSmsOtp } = api.auth.sendSmsOtp.useMutation();

  const smsLogin = async () => {
    store.setIsLoggingIn(true);

    await signIn("sms", {
      phone: store.email,
      password: store.otpCode,
      redirect: false,
    }).then((res) => {
      if (res?.ok) {
        const callbackUrl = router.query.callbackUrl;
        void router.replace(
          callbackUrl && callbackUrl !== "/" ? callbackUrl.toString() : "/dashboard",
        );
      } else {
        if (res?.error) {
          showToast(
            "Errore durante l'autenticazione. Per favore ricontrolla i dati inseriti.",
            "error",
          );
        }
        store.setIsLoggingIn(false);
      }
    });
  };

  return (
    <StepActionsContainer step={AuthStep.smsOtp}>
      <div>
        <Button
          type="submit"
          loading={store.isLoggingIn}
          onClick={(e) => {
            e.preventDefault();
            void smsLogin();
          }}>
          Accedi
        </Button>
      </div>
    </StepActionsContainer>
  );
};

export class SmsAuthMethod implements AuthMethodLogic {
  checkData() {
    return;
  }

  async auth() {
    return Promise.resolve();
  }

  renderComponent(_props: AuthStepProps): JSX.Element {
    return <SmsStep />;
  }

  renderActions(): JSX.Element {
    return <SmsStepActions />;
  }

  async goToNextStep() {
    // await 1s and return
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(() => {
          return;
        });
      }, 1000);
      return;
    });
  }
}
