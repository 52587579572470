import type { JSX } from "react";
import { type ReactNode } from "react";
import Link from "next/link";

import { Card } from "../card";
import { Instagram, Linkedin, Mail, Phone, Youtube } from "../icon";
import { Logo } from "../logo";

type CallbackProps = {
  callbackComponent?: ReactNode;
  isCallbackVisible?: boolean;
};

type AuthContainerProps = JSX.IntrinsicElements["div"] & CallbackProps;

export default function AuthContainer({
  callbackComponent,
  isCallbackVisible,
  children,
}: AuthContainerProps) {
  return (
    <div className="flex h-screen w-screen items-center justify-center bg-ice">
      <Card className="flex h-full w-full flex-col md:h-auto md:w-[830px] md:flex-row" naked>
        <Header callbackComponent={callbackComponent} isCallbackVisible={isCallbackVisible} />
        <Sidebar />
        <div className="flex w-full flex-1 flex-col p-6 md:block md:items-center lg:p-8">
          {children}
        </div>
      </Card>
    </div>
  );
}

const Header = ({ callbackComponent, isCallbackVisible }: CallbackProps) => {
  return (
    <div className="flex w-full items-center justify-between bg-blue p-6 md:hidden">
      <Logo className="size-16" color="white" />
      {isCallbackVisible && callbackComponent}
    </div>
  );
};

const Sidebar = () => {
  return (
    <div className="order-last flex min-w-40 flex-col justify-between gap-10 bg-blue p-6 text-white md:order-first md:w-1/5 md:rounded-l-[6px] lg:p-8">
      <Logo className="hidden size-16 md:block" color="white" />
      <div className="flex flex-col gap-1">
        <b>Contatti</b>
        <div className="flex flex-row gap-4 text-customGray-500 md:flex-col md:gap-1">
          <p className="flex items-center gap-2 text-sm">
            <Mail className="size-4" /> info@gility.it
          </p>
          <p className="flex items-center gap-2 text-sm">
            <Phone className="size-4" /> 800 931 231
          </p>
        </div>
        <div className="mt-4 flex gap-3">
          <Link href="https://it.linkedin.com/company/gility-italia" target="_blank">
            <Linkedin className="size-4" />
          </Link>
          <Link href="https://www.instagram.com/gility_it/" target="_blank">
            <Instagram className="size-4" />
          </Link>
          <Link href="https://www.youtube.com/@gilityofficial" target="_blank">
            <Youtube className="size-4" />
          </Link>
        </div>
      </div>
    </div>
  );
};
